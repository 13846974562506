import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarDismiss, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { TypeMessage } from '@shared/components/snack-bar/models/type-message';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SnackBarService {
  private snackBarRef!: MatSnackBarRef<unknown>;
  private snackBarId = 0;
  private messages: string[] = [];
  private snackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'end',
    verticalPosition: 'top',
    data: {},
  };
  private translateService = inject(TranslateService);

  constructor(private matSnackBar: MatSnackBar) {}

  get PAYMENT_NOT_CANCELLED(): string {
    return this.translateService.instant('snackBar.paymentNotCancelled');
  }

  get REVISE_PAYMENT_NOT_SUBMITTED(): string {
    return this.translateService.instant('snackBar.revisePaymentNotSubmitted');
  }

  get PAYMENT_NOT_RECEIVED(): string {
    return this.translateService.instant('snackBar.paymentNotReceived');
  }

  get CHANGES_SAVED(): string {
    return this.translateService.instant('snackBar.changesSaved');
  }

  public get CHANGES_NOT_SAVED(): string {
    return this.translateService.instant('snackBar.changesNotSaved');
  }

  public get ADDRESS_SAVED(): string {
    return this.translateService.instant('snackBar.addressSaved');
  }

  public get ADDRESS_NOT_SAVED(): string {
    return this.translateService.instant('snackBar.addressNotSaved');
  }

  public get SYSTEM_NOT_SAVED(): string {
    return this.translateService.instant('snackBar.systemNotSaved');
  }

  public get SYSTEM_SAVED(): string {
    return this.translateService.instant('snackBar.systemSaved');
  }

  public get ADDRESS_DELETED(): string {
    return this.translateService.instant('snackBar.addressDeleted');
  }

  public get VOUCHER_NOT_REALISED(): string {
    return this.translateService.instant('snackBar.voucherNotRealised');
  }

  public get VOUCHER_NOT_RETURN(): string {
    return this.translateService.instant('snackBar.voucherNotReturn');
  }

  public get PASSWORD_CHANGE(): string {
    return this.translateService.instant('snackBar.passwordChange');
  }

  public get REPORT_IS_GENERATING(): string {
    return this.translateService.instant('snackBar.reportIsGenerating');
  }

  public get REPORT_GENERATED_ERROR(): string {
    return this.translateService.instant('snackBar.reportGeneratedError');
  }

  public get REPORT_DELETED(): string {
    return this.translateService.instant('snackBar.reportDeleted');
  }

  public get REPORT_DELETED_ERROR(): string {
    return this.translateService.instant('snackBar.reportDeletedError');
  }

  public get USER_NOT_SAVED(): string {
    return this.translateService.instant('snackBar.userNotSaved');
  }

  public get USER_SAVED(): string {
    return this.translateService.instant('snackBar.userSaved');
  }

  public get USER_DELETED(): string {
    return this.translateService.instant('snackBar.userDeleted');
  }

  public get PASSWORD_CHANGED(): string {
    return this.translateService.instant('snackBar.passwordChanged');
  }

  public get PASSWORD_SAVED(): string {
    return this.translateService.instant('snackBar.passwordSaved');
  }

  public get PASSWORD_NOT_SAVED(): string {
    return this.translateService.instant('snackBar.passwordNotSaved');
  }

  public get SOMETING_WENT_WRONG(): string {
    return this.translateService.instant('snackBar.somethingWentWrong');
  }

  public get CHANGED_ACCOUNT_NUMBER(): string {
    return this.translateService.instant('snackBar.changedAccountNumber');
  }

  public get CHANGED_EMAIL_ADDRESS(): string {
    return this.translateService.instant('snackBar.changedEmailAddress');
  }

  public get DEACTIVATED_TEACHER(): string {
    return this.translateService.instant('snackBar.deactivatedTeacher');
  }

  public get EMAIL_SENT(): string {
    return this.translateService.instant('snackBar.emailSent');
  }

  public get GENERATED_NEW_CLINET_SECRET(): string {
    return this.translateService.instant('snackBar.generatedNewClientSecret');
  }

  public get COMPANY_HAS_BEEN_BLOCKED(): string {
    return this.translateService.instant('snackBar.companyHasBeenBlocked');
  }

  public get COMPANY_HAS_BEEN_UNBLOCKED(): string {
    return this.translateService.instant('snackBar.companyHasBeenUnblocked');
  }

  public get REVISION_SAVED(): string {
    return this.translateService.instant('snackBar.revisionSaved');
  }

  public get MOVE_TO_REJECTED(): string {
    return this.translateService.instant('snackBar.moveToRejected');
  }

  public get GENERATE_LIST_OF_TRANSFERS(): string {
    return this.translateService.instant('snackBar.generateListOfTransfers');
  }

  public get FORM_ERROR(): string {
    return this.translateService.instant('snackBar.formError');
  }

  public get INVOICE_DATA_CHANGED(): string {
    return this.translateService.instant('snackBar.invoiceDataChanged');
  }

  public get TOKEN_INVALID_GRANT(): string {
    return this.translateService.instant('snackBar.tokenInvalidGrand');
  }

  public get INVOICE_DATA_CHANGE_ERROR(): string {
    return this.translateService.instant('snackBar.invoiceDataChangeError');
  }

  public get PAYOFF_STATUS_SAVED(): string {
    return this.translateService.instant('snackBar.payoffStatusSaved');
  }

  public get BLOCK_STATUS_SAVED(): string {
    return this.translateService.instant('snackBar.blockStatusSaved');
  }

  public get UNBLOCK_STATUS_SAVED(): string {
    return this.translateService.instant('snackBar.unblockStatusSaved');
  }

  public get VERIFICATION_FILE_ERROR(): string {
    return this.translateService.instant('snackBar.verificationFileError');
  }

  public get ADDING_FILE_ERROR(): string {
    return this.translateService.instant('snackBar.addingFileError');
  }

  public get INVALID_CODE(): string {
    return this.translateService.instant('snackBar.invalidCodeError');
  }

  public get LOGOUT_ERROR(): string {
    return this.translateService.instant('snackBar.logoutError');
  }

  public get RSPO_NOT_FOUND_ERROR(): string {
    return this.translateService.instant('snackBar.rspoNotFoundError')
  }

  public openSuccess(message: string, boldText?: string): void {
    this.open(message, TypeMessage.SUCCESS, boldText);
  }

  public openWarning(message: string, boldText?: string): void {
    this.open(message, TypeMessage.WARN, boldText);
  }

  public openError(message: string): void {
    this.open(message, TypeMessage.ERROR);
  }

  public openInfo(message: string | string[], title?: string, isHTML?: boolean, delay?: number): void {
    this.open(message, TypeMessage.INFO, undefined, title, isHTML, delay);
  }

  public clear(): void {
    this.matSnackBar.dismiss();
  }

  public nextSnackBar(): void {
    if (this.snackBarId < this.messages.length - 1) {
      this.snackBarId++;
      this.openSingleMessageListenOnDismiss();
    }
  }

  public previousSnackBar(): void {
    if (this.snackBarId > 0) {
      this.snackBarId--;
      this.openSingleMessageListenOnDismiss();
    }
  }

  private open(
    message: string | string[],
    type: TypeMessage,
    boldText?: string,
    title?: string,
    isHTML?: boolean,
    delay?: number,
  ): void {
    this.snackBarConfig = {
      ...this.snackBarConfig,
      panelClass: ['snack-bar', this.getClassForSnackBarType(type)],
      data: {
        boldText,
        type,
        title,
        isHTML,
        numberOfMessages: Array.isArray(message) ? message.length : null,
      },
    };
    if (Array.isArray(message)) {
      this.messages = [...message];
      this.openSingleMessageListenOnDismiss();
    } else {
      this.snackBarRef = this.matSnackBar.openFromComponent(SnackBarComponent, this.getSnackBarConfig(message));
    }
    setTimeout(() => this.clear(), delay ? delay : 5000);
  }

  private getClassForSnackBarType(type: TypeMessage): string {
    let typeClass;
    switch (type) {
      case TypeMessage.WARN:
        typeClass = 'snack-bar-warning';
        break;
      case TypeMessage.SUCCESS:
        typeClass = 'snack-bar-success';
        break;
      case TypeMessage.ERROR:
        typeClass = 'snack-bar-error';
        break;
      case TypeMessage.INFO:
        typeClass = 'snack-bar-info';
        break;
    }
    return typeClass;
  }

  private openSingleMessageListenOnDismiss(): Subscription {
    if (this.messages.length) {
      const message = this.messages[this.snackBarId] ?? '';
      this.snackBarRef = this.matSnackBar.openFromComponent(SnackBarComponent, this.getSnackBarConfig(message));
    } else {
      this.snackBarId = 0;
      return new Subscription();
    }
    return this.snackBarRef.afterDismissed().subscribe((value: MatSnackBarDismiss) => {
      if (value.dismissedByAction) {
        this.messages.splice(this.snackBarId, 1);
        if (this.snackBarId > this.messages.length - 1) {
          this.snackBarId--;
        }
        this.snackBarConfig.data.numberOfMessages = this.messages.length;
        this.openSingleMessageListenOnDismiss();
      }
    });
  }

  private getSnackBarConfig(message: string): MatSnackBarConfig {
    return {
      ...this.snackBarConfig,
      data: {
        ...this.snackBarConfig.data,
        message: message,
        currentMessageNumber: this.snackBarId + 1,
      },
    };
  }
}
