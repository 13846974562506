import { Injectable, inject } from '@angular/core';
import { EnvConfigurationService } from '@core/config/services/envConfiguration.service';
import { environment as TestEnvironment } from '@environment/environment.test';
import { environment as ProdEnvironment } from '@environment/environment.prod';
import { environment as IntEnvironment } from '@environment/environment.int';

@Injectable({
  providedIn: 'root',
})
export class MatomoService {
  private readonly envConfigurationService = inject(EnvConfigurationService);

  loadMatomoScript(): void {
    if (!this.envConfigurationService.config.matomoScriptEnabled) {
      return;
    }

    const _mtm = (window as any).__mtm || [];

    _mtm.push({ 'mtm.startTime': new Date().getTime(), 'event': 'mtm.Start' });
    (window as any).__mtm = _mtm;

    (() => {
      const script = document.createElement('script');
      script.async = true;

      script.src = loadMatomoScript(this.envConfigurationService.config.keycloakConfigType);

      document.head.appendChild(script);
    })();
  }
}

function loadMatomoScript(environmentType: string): string {
  switch (environmentType) {
    case 'test':
      return TestEnvironment.matomoScriptSrc;
    case 'prod':
      return ProdEnvironment.matomoScriptSrc;
    case 'int':
      return IntEnvironment.matomoScriptSrc;
    default:
      return '';
  }
}
