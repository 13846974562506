import { inject, Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { EnvConfigurationService } from '@core/config/services/envConfiguration.service';
import { PASSWORD_CHANGE_TOKEN } from '@shared/tokens/password-change-token';
import { BehaviorSubject, filter } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { KeycloakConfig } from '@core/models/config/keycloak-config';
import { KeycloakConfigService } from '@core/services/keycloak-config.service';
import { BdnJwt } from '@core/models/bdn-jwt';
import { OAuthEvent } from 'angular-oauth2-oidc/events';
import { OAuthStorageService } from "@core/config/services/o-auth-storage.service";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";

@Injectable({
  providedIn: 'root',
})
export class AuthConfigService {
  private passwordChangeToken: BehaviorSubject<boolean> = inject(PASSWORD_CHANGE_TOKEN);

  private keycloakConfig: KeycloakConfig;

  constructor(
    public oauthService: OAuthService,
    private readonly envConfig: EnvConfigurationService,
    private readonly keycloakConfigService: KeycloakConfigService,
    private readonly storageService: OAuthStorageService
  ) {}

  private _decodedAccessToken: string;

  get decodedAccessToken() {
    return this._decodedAccessToken;
  }

  private _decodedIDToken: unknown;

  get decodedIDToken() {
    return this._decodedIDToken;
  }

  async initAuth(): Promise<void> {
    return new Promise<void>((resolveFn: () => void) => {
      this.oauthService.configure(this.createAuthConfig());
      this.oauthService.setStorage(this.storageService);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      this.oauthService.events
        .pipe(
          filter((e: OAuthEvent) => {
            return e.type === 'token_received' || e.type === 'token_refreshed';
          }),
        )
        .subscribe(() => this.handleNewToken());
      let redirectUri = this.keycloakConfig?.redirectUri;
      if (document.location.search.includes('?password_change=1')) {
        redirectUri = this.keycloakConfig?.redirectUri + '?password_change=1';
        this.passwordChangeToken.next(true);
      }
      this.oauthService
        .loadDiscoveryDocumentAndTryLogin({customRedirectUri: redirectUri})
        .catch(() => {
          this.oauthService.redirectUri = this.keycloakConfig?.redirectUri + '?password_change=1';
        })
        .then(() => {
          this.manageToken(resolveFn);
        });
    });
  }

  private handleNewToken(): void {
    this._decodedAccessToken = this.oauthService.getAccessToken();
    this._decodedIDToken = this.oauthService.getIdToken();
    const decodedToken: BdnJwt = jwt_decode(this._decodedAccessToken);
    const userRoleFromToken: string = decodedToken[ 'realm_access' ][ 'roles' ].find(
      (el: string) => el === 'Administrator' || el === 'Sprzedawca' || el === 'KPRM' || el === 'MEIN',
    );
    localStorage.setItem('userRole', userRoleFromToken);
    const refreshToken = this.oauthService.getRefreshToken();
    this.storageService.setRefreshToken(refreshToken)
  }

  private createAuthConfig(): AuthConfig {
    this.keycloakConfig = this.keycloakConfigService.getKeycloakConfig(this.envConfig.config?.keycloakConfigType);
    return {
      issuer: this.keycloakConfig?.issuer,
      redirectUri: this.keycloakConfig?.redirectUri,
      clientId: this.keycloakConfig?.clientId,
      responseType: this.keycloakConfig?.responseType,
      useSilentRefresh: false,
      requireHttps: this.keycloakConfig?.requireHttps,
      showDebugInformation: this.keycloakConfig?.showDebugInformation,
      disableAtHashCheck: this.keycloakConfig?.disableAtHashCheck,
    };
  }

  private manageToken(resolveFn: () => void): void {
    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
      this.oauthService.setupAutomaticSilentRefresh();
    }
    resolveFn();
  }
}
