import { Przedsiebiorca } from '@api/przedsiebiorca';

export const companyData: Przedsiebiorca = {
  nazwaFirmy: 'Moja Firma',
  nip: '123456789',
  numerKonta: '82102052260000610204177895',
  adresSiedziby: {
    miejscowosc: 'Lublin',
    wojewodztwo: 'Lubelskie',
    powiat: 'Lublin',
    gmina: 'Lublin',
    kodPocztowy: '20715',
    ulica: 'Moja Ulica',
    numerDomu: '51',
    numerLokalu: '24',
  },
  daneOsobySkladajacejWniosek: {
    imie: 'Test',
    nazwisko: 'Test',
    numerPesel: '22222222222',
  },
  daneKontaktowe: {
    numerTelefonu: '123456789',
    adresEmail: 'test@test.test',
    adresStronyInternetowej: 'www.coi@gov.pl',
  },
};