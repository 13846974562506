export const configDataMock = {
  keycloakConfigType: 'local',
  redirects: {
    news: '#',
    sendApplication: '#',
    traderZone: 'http://localhost:8080/przedsiebiorca',
  },
  startProgrammeDate: {
    year: 2022,
    month: 0,
    day: 1,
  },
  basePath: 'http://localhost:8080/api',
};
