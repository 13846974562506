import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { MatMenuModule } from '@angular/material/menu';
import { FlexModule } from '@angular/flex-layout';
import { SnackBarService } from '@shared/components/snack-bar/services/snack-bar.service';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, MatIconModule, MatMenuModule, FlexModule],
  declarations: [SnackBarComponent],
  exports: [SnackBarComponent],
  providers: [SnackBarService],
})
export class SnackBarModule {}
