import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from "@core/config/services/session.service";

@Injectable()
export class SessionRecoveryInterceptor implements HttpInterceptor {
  private readonly sessionService = inject(SessionService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.url.includes('/api')) {
      this.sessionService.updateExpirationDateLogout();
    }

    return next.handle(request);
  }
}
