import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, map } from 'rxjs';

export class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly prefixArray: { path: string; fileName: string }[],
  ) {}

  getTranslation(lang: string) {
    const requests = this.prefixArray.map(resource => {
      return this.httpClient.get([resource.path, lang, resource.fileName].join('/'), {
        headers: { 'Cache-Control': 'no-cache' },
      });
    });

    return forkJoin(requests).pipe(map(res => res.reduce((acc, val) => (acc = { ...acc, ...val }), {})));
  }
}
