import { Component } from '@angular/core';
import { MatomoService } from '@shared/services/matomo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(matomoService: MatomoService) {
    matomoService.loadMatomoScript();
  }
}
