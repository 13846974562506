import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { TypeMessage } from '@shared/components/snack-bar/models/type-message';
import { SnackBarService } from '@shared/components/snack-bar/services/snack-bar.service';

@Component({
  selector: 'app-snack-bar',
  styleUrls: ['./snack-bar.component.scss'],
  templateUrl: './snack-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {
  public data = inject(MAT_SNACK_BAR_DATA);
  private snackBarService = inject(SnackBarService);

  public get snackBarTitle(): string {
    let title = '';
    switch (this.data.type) {
      case TypeMessage.WARN:
        title = 'Uwaga';
        break;
      case TypeMessage.SUCCESS:
        title = 'Sukces';
        break;
      case TypeMessage.ERROR:
        title = 'Błąd';
        break;
      case TypeMessage.INFO:
        title = 'Informacja';
        break;
      default:
        break;
    }
    return title;
  }

  public get snackBarIcon(): string {
    let title = '';
    switch (this.data.type) {
      case TypeMessage.WARN:
        title = 'warning';
        break;
      case TypeMessage.SUCCESS:
        title = 'check';
        break;
      case TypeMessage.ERROR:
        title = 'error';
        break;
      case TypeMessage.INFO:
        title = 'info';
        break;
      default:
        break;
    }
    return title;
  }

  constructor(private readonly matSnackBarRef: MatSnackBarRef<SnackBarComponent>) {}

  public close(): void {
    this.matSnackBarRef.dismissWithAction();
  }

  public previousMessage(): void {
    this.snackBarService.previousSnackBar();
  }

  public nextMessage(): void {
    this.snackBarService.nextSnackBar();
  }
}
