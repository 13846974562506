export const environment = {
  basePath: 'https://test-backoffice.bon.prs.internal:8443/api',
  production: false,
  keycloakDisable: false,
  keycloakConfig: {
    clientId: 'bon-sprzedawca-frontend',
    issuer: 'https://test.bon.prs.internal/auth/realms/sprzedawca',
    redirectUri: 'https://test.bon.prs.internal/przedsiebiorca',
    responseType: 'code',
    requireHttps: false,
    showDebugInformation: false,
    disableAtHashCheck: true,
  },
  matomoScriptSrc: 'https://pi-ogp.coi.gov.pl/js/container_CoySMTUt.js',
};
