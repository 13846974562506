import { Injectable } from '@angular/core';
import { OAuthStorage } from "angular-oauth2-oidc";
import { CookieOptions, CookieService } from "ngx-cookie-service";

@Injectable({providedIn: 'root'})
export class OAuthStorageService extends OAuthStorage {
  private readonly refreshTokenKey = 'refresh_token';

  constructor(private readonly cookieService: CookieService) {
    super()
  }

  override getItem(key: string): string | null {
    if (key === 'refresh_token') {
      return this.cookieService.get(this.refreshTokenKey);
    }
    return localStorage.getItem(key);
  }

  override removeItem(key: string): void {
    if (key === 'refresh_token') {
      this.cookieService.delete(this.refreshTokenKey);
    } else {
      localStorage.removeItem(key);
    }
  }

  override setItem(key: string, data: string): void {
    if (key === 'refresh_token') {
      const options: CookieOptions = {secure: true, sameSite: 'Lax'};
      this.cookieService.set(this.refreshTokenKey, data, options);
    } else {
      localStorage.setItem(key, data);
    }
  }

  setRefreshToken(refreshToken: string): void {
    if (!refreshToken) return;

    const options: CookieOptions = {secure: true, sameSite: 'Strict',};
    this.cookieService.set(this.refreshTokenKey, refreshToken, options);
  }
}
