import { PageListaSklepow } from '@api/przedsiebiorca';

export const posMock: PageListaSklepow = {
  totalPages: 1,
  totalElements: 6,
  number: 0,
  size: 10,
  content: [
    {
      id: 83,
      nazwaSklepu: 'COI',
      miejscowoscLubAdresWww: 'https://www.coi.gov.pl/',
      formaSklepu: 'internetowy',
    },
    {
      id: 186,
      nazwaSklepu: 'Nowy',
      miejscowoscLubAdresWww: 'www.nowy.pl',
      formaSklepu: 'internetowy',
    },
    {
      id: 221,
      nazwaSklepu: 'Test3',
      miejscowoscLubAdresWww: 'www.test2.pl',
      formaSklepu: 'internetowy',
    },
  ],
  empty: false,
};
