import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthConfigService } from '@core/config/services/auth-config.service';
import { EnvConfigurationService } from '@core/config/services/envConfiguration.service';
import { from, switchMap } from 'rxjs';
import { environment } from '@environment/environment';
import { OAuthModule } from 'angular-oauth2-oidc';

export function initApp(envConfigService: EnvConfigurationService, authConfigService: AuthConfigService) {
  return () => {
    return new Promise((resolve, reject) => {
      envConfigService.loaded$
        .pipe(
          switchMap(() => {
            if (!environment.keycloakDisable) {
              return from(authConfigService.initAuth());
            }
            return Promise.resolve(true);
          }),
        )
        .subscribe({
          next: () => resolve(true),
          error: err => {
            console.error(err);
            return reject();
          },
        });
    });
  };
}

@NgModule({
  declarations: [],
  imports: [OAuthModule.forRoot()],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (envConfigService: EnvConfigurationService) => () => envConfigService.loadConfig(),
      deps: [EnvConfigurationService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [EnvConfigurationService, AuthConfigService],
      multi: true,
    },
  ],
})
export class AuthConfigModule {}
