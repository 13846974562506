<div fxLayout="row" fxLayoutAlign="space-between start">
  <div fxLayout="row" fxLayoutAlign="none center">
    <div class="p-1.5 pr-3">
      <mat-icon svgIcon="{{ snackBarIcon }}" aria-hidden="true"></mat-icon>
    </div>
    <div>
      <p class="fs-20 pb-1.5">
        <strong>{{ data.title ? data.title : snackBarTitle }}</strong>
      </p>
      <p *ngIf="!data.isHTML">
        {{ data.message }} <b>{{ data.boldText }}</b>
      </p>
      <p *ngIf="data.isHTML" [innerHTML]="data.message"></p>
      <p *ngIf="data.numberOfMessages && data.numberOfMessages !== 1" class="counter">
        <button mat-menu-item class="icon-btn" (click)="previousMessage()" *ngIf="data.currentMessageNumber !== 1">
          <mat-icon svgIcon="chevron_left" aria-hidden="true"></mat-icon>
        </button>
        <span>{{ data.currentMessageNumber }} z <span class="counter-highlighted">{{ data.numberOfMessages }}</span></span>
        <button
          mat-menu-item
          class="icon-btn"
          *ngIf="data.currentMessageNumber !== data.numberOfMessages"
          (click)="nextMessage()">
          <mat-icon svgIcon="chevron_right" aria-hidden="true"></mat-icon>
        </button>
      </p>
    </div>
  </div>
  <a (click)="close()" (keyup.enter)="close()" role="button" tabindex="0">
    <mat-icon svgIcon="close" aria-hidden="true"></mat-icon>
  </a>
</div>
