export const environment = {
  basePath: 'https://backoffice.laptopdlanauczyciela.gov.pl:8443/api',
  production: true,
  keycloakDisable: false,
  keycloakConfig: {
    clientId: 'bon-sprzedawca-frontend',
    issuer: 'https://laptopdlanauczyciela.gov.pl/auth/realms/sprzedawca',
    redirectUri: 'https://laptopdlanauczyciela.gov.pl/przedsiebiorca',
    responseType: 'code',
    requireHttps: false,
    showDebugInformation: false,
    disableAtHashCheck: true,
  },
  matomoScriptSrc: 'https://pi-ogp.coi.gov.pl/js/container_gUMOa9cs.js',
};
