import { inject, Injectable } from '@angular/core';
import { OAuthService } from "angular-oauth2-oidc";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private _expirationDateToken: Date;
  private _expirationDateLogout: Date;
  private _warningVisibility = false;
  private readonly oauthService = inject(OAuthService);

  get expirationDateToken(): Date {
    return this._expirationDateToken;
  }

  set expirationDateToken(value: Date) {
    this._expirationDateToken = value;
  }

  get expirationDateLogout(): Date {
    return this._expirationDateLogout;
  }

  set expirationDateLogout(value: Date) {
    this._expirationDateLogout = value;
  }

  get warningVisibility(): boolean {
    return this._warningVisibility;
  }

  set warningVisibility(value: boolean) {
    this._warningVisibility = value;
  }

  public updateExpirationDateLogout(): void {
    const nowDate = new Date();
    this.expirationDateLogout = new Date(nowDate.getTime() + 60 * 15 * 1000);
  }

  public lengthenSession(): void {
    this.oauthService.refreshToken().then(() => {
      this.updateExpirationDateToken();
      this._warningVisibility = false;
    });
  }

  private updateExpirationDateToken(): void {
    const newExpDate = new Date(JSON.parse(<string>localStorage.getItem('expires_at')));
    if (!this.expirationDateToken || this.expirationDateToken.getTime() !== newExpDate.getTime()) {
      this.expirationDateToken = newExpDate;
      this.updateExpirationDateLogout();
    }
  }


}
