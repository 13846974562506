import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { delay, dematerialize, materialize, mergeMap, Observable, of } from 'rxjs';
import { environment } from '@environment/environment.development';
import { posMock } from '@mock/data/pos-mock';
import { companyData } from '@mock/data/company-mock';
import { systemsMock } from '@mock/data/systems-mock';
import { rolesData } from '@mock/data/roles.mock';
import { configDataMock } from '@mock/data/config-mock';

@Injectable()
export class MockBackendInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { url, method } = request;
    if (!environment.keycloakDisable) return next.handle(request);

    return of(null).pipe(mergeMap(handleRoute)).pipe(materialize()).pipe(delay(500)).pipe(dematerialize());

    function handleRoute() {
      switch (true) {
        case url.includes('api/config') && method === 'GET':
          return getConfig();
        case url.match(/\/pobierzListePunktowSprzedazy$/) && method === 'GET':
          return getPOS();
        case url.includes('pobierzDanePrzedsiebiorcy') && method === 'GET':
          return getCompanyData();
        case url.includes('teryt/place') && method === 'GET':
          return getTerytPlace();
        case url.includes('pobierzListeSystemow') && method === 'GET':
          return getSystem();
        case url.includes('rezygnacjaPrzedsiebiorcy') && method === 'DELETE':
          return isResignation();
        case url.includes('przedsiebiorcaAktywny') && method === 'GET':
          return isResignation();
        case url.includes('pobierzListeRol') && method === 'GET':
          return getRoles();
        default:
          return next.handle(request);
      }
    }

    function getConfig() {
      return ok(configDataMock);
    }

    function getPOS() {
      return ok(posMock);
    }

    function getCompanyData() {
      return ok(companyData);
    }

    function getTerytPlace() {
      return ok(['Lublin']);
    }

    function getSystem() {
      return ok(systemsMock);
    }

    function isResignation() {
      return ok(true);
    }

    function getRoles() {
      return ok(rolesData);
    }

    function ok(body?: unknown) {
      return of(new HttpResponse({ status: 200, body }));
    }
  }
}
