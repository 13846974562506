import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EnvConfiguration } from '@core/models/config/envConfiguration';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class EnvConfigurationService {
  public loaded$: Subject<boolean> = new Subject<boolean>();
  public config: EnvConfiguration;

  constructor(private httpClient: HttpClient) {}

  public get firstOffDateIsPast(): boolean {
    const firstOffDate: Date = new Date(this.config.firstOffDate);
    return firstOffDate.getTime() - new Date().getTime() <= 0;
  }

  public get secondOffDateIsPast(): boolean {
    const secondOffDate: Date = new Date(this.config.secondOffDate);
    return secondOffDate.getTime() - new Date().getTime() <= 0;
  }

  public loadConfig(): void {
    this.httpClient.get<EnvConfiguration>(`/api/config`).subscribe((envConfiguration: EnvConfiguration) => {
      this.config = envConfiguration;
      this.loaded$.next(true);
    });
  }
}
