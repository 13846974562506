import { TranslateService } from '@ngx-translate/core';

export function initializeTranslations(translateService: TranslateService) {
  return () => {
    translateService.addLangs(['pl']);
    translateService.setDefaultLang('pl');

    return translateService.use('pl');

    //@TODO: Use when second language is added
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang && browserLang.match(/pl|en/) ? browserLang : 'pl');
  };
}
