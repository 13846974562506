import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { SnackBarService } from '@shared/components/snack-bar/services/snack-bar.service';
import { LOGOUT_TOKEN } from '@shared/tokens/logout-token';

@Injectable()
export class BackendErrorsInterceptor implements HttpInterceptor {
  private logoutToken: BehaviorSubject<boolean> = inject(LOGOUT_TOKEN);

  constructor(public snackbarService: SnackBarService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(req => {
        if (req instanceof HttpErrorResponse) {
          if (this.hasBlobAndJSONError(req)) {
            this.convertBlobToNotification(req);
          } else {
            this.convertErrorToNotification(req, request);
          }
        }
        return throwError(req);
      }),
    );
  }

  private hasBlobAndJSONError(req: HttpErrorResponse): boolean {
    return req.error instanceof Blob && req.error.type === 'application/json';
  }

  private convertBlobToNotification(req: HttpErrorResponse): void {
    try {
      const file = new FileReader();
      file.onload = (fileReaderProgressEvent: ProgressEvent<FileReader>) => {
        const { result } = fileReaderProgressEvent.currentTarget as FileReader;
        const msg = JSON.parse(result as string);
        this.snackbarService.openError(msg.message);
      };
      file.readAsText(req.error);
    } catch (error) {
      this.snackbarService.openError(req.status.toString());
    }
  }

  private excludeErrorCodesFromAutoNotification() {
    return [
      '20220427:080000',
      '20240808:130000',
    ];
  }

  private convertErrorToNotification(errorReq: HttpErrorResponse, request: HttpRequest<unknown>): void {
    const linkOfTheWebsiteUserCame = document.referrer;
    setTimeout(() => {
      if ((!linkOfTheWebsiteUserCame.includes('wyszukiwarka') && !this.logoutToken.value) || !this.logoutToken.value) {
        if ((errorReq?.status === 403 || errorReq?.status === 401) && !localStorage.getItem('access_token')) {
          window.location.reload();
        }
        if (errorReq?.status === 400 && !this.excludeErrorCodesFromAutoNotification().includes(errorReq?.error?.code) && errorReq?.error?.message) {
          this.snackbarService.openError(errorReq.error.message);
        }
        if (errorReq?.status === 400 && errorReq?.error?.error === 'invalid_grant') {
          this.snackbarService.openError(this.snackbarService.TOKEN_INVALID_GRANT);
        }
        if (
          !(errorReq?.status === 400) &&
          (!request.url.includes('/raporty/generatorRaportu') || (errorReq?.status >= 500 && errorReq?.status < 600))
        ) {
          this.snackbarService.openError(this.snackbarService.SOMETING_WENT_WRONG);
        }
      }
    }, 300);
  }
}
