import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '@shared/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomTranslateHttpLoader } from '@core/translate/CustomTranslateHttpLoader';
import { CoreModule } from '@core/core.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from '@shared/custom/matPaginatorCustom';
import { BackendErrorsInterceptor } from '@core/interceptors/backend-errors.interceptor';
import { SnackBarModule } from '@shared/components/snack-bar/snack-bar.module';
import { AuthConfigModule } from '@core/config/auth-config/auth-config.module';
import { MockBackendInterceptor } from '@core/interceptors/mock-backend.interceptor';
import { KeycloakInterceptor } from '@core/interceptors/keycloak.interceptor';
import { AuthConfigService } from '@core/config/services/auth-config.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { TitleStrategy } from '@angular/router';
import { TemplatePageTitleStrategyService } from '@shared/services/template-page-title-strategy.service';
import { MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { IsProgramActiveGuard } from '@shared/guards/is-program-active.guard';
import { DatePipe } from '@angular/common';
import { SessionRecoveryInterceptor } from "@core/interceptors/session-recovery.interceptor";

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateHttpLoader(http, [
    { path: '/assets/i18n', fileName: 'core.json' },
    { path: '/assets/i18n', fileName: 'kprm.json' },
    { path: '/assets/i18n', fileName: 'errors.json' },
    { path: '/assets/i18n', fileName: 'entrepreneur.json' },
    { path: '/assets/i18n', fileName: 'snack-bar.json' },
    { path: '/assets/i18n', fileName: 'search-engine.json' },
  ]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    HttpClientModule,
    MaterialModule,
    SnackBarModule,
    AuthConfigModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    IsProgramActiveGuard,
    AuthConfigService,
    OAuthService,
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: MatPaginatorIntl, useClass: CustomPaginator },
    { provide: HTTP_INTERCEPTORS, useClass: BackendErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionRecoveryInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: MockBackendInterceptor,
    },
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakInterceptor, multi: true },
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategyService },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true} }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
