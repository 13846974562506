export const environment = {
  basePath: 'https://172.31.0.30/api',
  production: false,
  keycloakDisable: false,
  keycloakConfig: {
    clientId: 'bon-sprzedawca-frontend',
    issuer: 'https://172.31.0.29/auth/realms/sprzedawca',
    redirectUri: 'http://localhost:8080/przedsiebiorca',
    responseType: 'code',
    requireHttps: false,
    showDebugInformation: true,
    disableAtHashCheck: true,
  },
};
