import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsProgramActiveGuard } from '@shared/guards/is-program-active.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'wyszukiwarka',
    pathMatch: 'full',
  },
  {
    canActivate: [IsProgramActiveGuard],
    path: 'wyszukiwarka',
    loadChildren: () => import('@pages/search-engine/search-engine.module').then(m => m.SearchEngineModule),
  },
  {
    canActivate: [IsProgramActiveGuard],
    path: 'mein',
    loadChildren: () => import('@pages/mein/mein.module').then(m => m.MeinModule),
  },
  {
    canActivate: [IsProgramActiveGuard],
    path: 'operator',
    loadChildren: () => import('@pages/kprm/kprm.module').then(m => m.KprmModule),
  },
  {
    canActivate: [IsProgramActiveGuard],
    path: 'przedsiebiorca',
    loadChildren: () => import('@pages/entrepreneur/entrepreneur.module').then(m => m.EntrepreneurModule),
  },
  {
    path: 'program-wylaczony',
    loadComponent: () =>
      import('@shared/components/program-deactivated/program-deactivated.component').then(
        m => m.ProgramDeactivatedComponent,
      ),
  },
  {
    path: '**',
    loadChildren: () => import('@pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
