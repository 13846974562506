import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { EnvConfigurationService } from "@core/config/services/envConfiguration.service";

@Injectable()
export class IsProgramActiveGuard {
	constructor(
		private readonly router: Router,
		private readonly envConfigurationService: EnvConfigurationService,
	) {}

	canActivate(_activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean {
		const redirectUrl = _activatedRouteSnapshot.data['redirect'];
		const currentUrl = routerStateSnapshot.url;
		const firstOffDateIsPast = this.envConfigurationService.firstOffDateIsPast;
		const secondOffDateIsPast = this.envConfigurationService.secondOffDateIsPast;

		if (!firstOffDateIsPast && !secondOffDateIsPast) {
			return true;
		}

		if (secondOffDateIsPast) {
			this.router.navigate(['/program-wylaczony']);
			return false;
		}

		if (currentUrl === '/przedsiebiorca/przyjmowanie-platnosci/przyjmij-platnosc') {
			this.router.navigate([redirectUrl]);
			return false;
		}

		return false;
	}
}