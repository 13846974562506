import { Injectable } from '@angular/core';
import { KeycloakConfig } from '@core/models/config/keycloak-config';
import { environment } from '@environment/environment';
import { environment as TestEnvironment } from '@environment/environment.test';
import { environment as DevEnvironment } from '@environment/environment.development';
import { environment as LocalEnvironment } from '@environment/environment.local';
import { environment as ProdEnvironment } from '@environment/environment.prod';
import { environment as IntEnvironment } from '@environment/environment.int';

@Injectable({
  providedIn: 'root',
})
export class KeycloakConfigService {
  public getKeycloakConfig(environmentType: string): KeycloakConfig {
    switch (environmentType) {
      case 'development':
        return DevEnvironment.keycloakConfig;
      case 'test':
        return TestEnvironment.keycloakConfig;
      case 'local':
        return LocalEnvironment.keycloakConfig;
      case 'int':
        return IntEnvironment.keycloakConfig;
      case 'prod':
        return ProdEnvironment.keycloakConfig;
      default:
        return environment.keycloakConfig;
    }
  }
}
