import { System } from '@api/uprawnienia';

export const systemsMock: System[] = [
  {
    id: '51ad1fd0-f6bf-4cb8-9c96-efff0273087f',
    nazwa: 'Future Home',
    clientId: '1060000062-futurehome',
    clientSecret: '99d53d63-5385-483f-97e9-bfac3870aab6',
  },
  {
    clientId: '1235',
    clientSecret: '31',
    nazwa: 'Nazwa sys2',
    id: '123574563hjmg2134',
  },
  {
    clientId: '12312',
    clientSecret: '31121',
    nazwa: 'Nazwa sys3',
    id: '12364567456dsf32134',
  },
  {
    clientId: '12343',
    clientSecret: '3215',
    nazwa: 'Nazwa sys4',
    id: '1235546456745134',
  },
  {
    clientId: '12311',
    clientSecret: '32166',
    nazwa: 'Nazwa sys5',
    id: '123546456745632134',
  },
  {
    clientId: '1233',
    clientSecret: '32132',
    nazwa: 'Nazwa sys6',
    id: '12355445632134',
  },
  {
    clientId: '1234',
    clientSecret: '321',
    nazwa: 'Nazwa sys7',
    id: '1235546456745632134',
  },
];
